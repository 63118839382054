<template>
  <div class="changeSteps" :class="{changeContainer : isShowGetCardAd}" :style="`background: ${theme == 'TianPeng'?'#FFEFD1':theme == 'XiaoMing'?'#FFEFD1':''};`">
		 <!-- 申请状态 -->
    <div class="steps" v-if="!isShowGetCardAd">
      <div class="steps_con">
        <div
          class="step_status"
          :class="{ step_status_1: [1,5].includes(changeCardData.status) }"
        >
          <div class="step_icons">
            <div class="step_icon1"></div>
          </div>
          <div class="step_text">待申请</div>
        </div>
        <div class="step_underline"></div>
        <div
          class="step_status"
          :class="{ step_status_2: changeCardData.status == 2 }"
        >
          <div class="step_icons">
            <div class="step_icon2"></div>
          </div>
          <div class="step_text">已申请</div>
        </div>
        <div class="step_underline"></div>
        <div
          class="step_status"
          :class="{ step_status_3: changeCardData.status == 3 }"
        >
          <div class="step_icons">
            <div class="step_icon3"></div>
          </div>
          <div class="step_text">已发货</div>
        </div>
      </div>
    </div>
    <!-- 换卡广告 -->
		<template v-if="isShowGetCardAd">
			<img class="img" v-if="theme == 'TianPeng'" :src="require('_ASSETS_/image/personal/bannerBj.png')" alt="">
			<img class="img" v-else-if="theme == 'XiaoMing'" :src="require('_ASSETS_/image/personal/bannerBj.png')" alt="">
			<img class="img" v-else :src="require('_ASSETS_/image/personal/changeCardExhibition.png')" alt="">
		</template>
		<!-- 申请须知 -->
    <div class="tips" :class="{isShowGetCardAdTips : isShowGetCardAd}">
      <div class="tips_text" :class="{ tips_border : !isShowGetCardAd}" v-if="[1,6,7,8].includes(changeCardData.status)">
				<div v-if="isShowGetCardAd" class="tips_text_list">
          <div>● 新卡不限APP，通用流量，全国可用;</div>
          <div>● 收到新卡后，旧卡的套餐可以转移到新卡;</div>
          <div v-if="['ZhongXun','AnFang','YuYin'].includes(theme)">● 新卡置换后原套餐资费不变，额外赠送100元优惠券;</div>
        </div>
        <div v-else>
          由于您的卡片已失效，现为您更换新卡，请填写地址由我司免费为您寄出，请务必填写正确的地址信息。
        </div>
        <!-- {{isShowGetCardAd ? '恭喜您成为幸运用户，可以更换电信高速体验卡,请您仔细填写收件地址，我们将会为您尽快的寄出。' : '由于您的卡片已失效，现为您更换新卡，请填写地址由我司免费为您寄出，请务必填写正确的地址信息。'}} -->
      </div>
      <div class="tips_text" :class="{ tips_border : !isShowGetCardAd}" v-if="changeCardData.status == 5">
        您的申请已经被驳回。驳回原因:{{changeCardData.auditInfo}}。您可以重新提交。
      </div>
      <div class="tips_text" :class="{ tips_border : !isShowGetCardAd}" v-if="changeCardData.status == 2">
				您已经提交申请，您收到后请在此处输入新收到的卡号,
				{{isShowGetCardAd ? '' : '系统将会将已失效卡片的数据转移到新卡'}}<br>
				PS:卡号请务必输入正确
      </div>
      <div class="tips_text" :class="{ tips_border : !isShowGetCardAd}" v-if="[3,9].includes(changeCardData.status)">
        您的卡片已经发货，您可以自行查看物流信息。如您已经收到新卡，请点击确认收货后输入新卡卡号,<br>
				{{isShowGetCardAd ? '请务必输入正确卡号，一旦输入错误将无法找回数据' : ''}}
      </div>
    </div>
    <!-- 申请信息 -->
    <div class="content">
      <van-form @submit="onSubmit" ref="formData">
        <van-field
          v-model="form.card"
          input-align="left"
          label="卡号"
          placeholder="请输入卡号"
          disabled
        />
        <van-field
          v-model="newCard"
          input-align="left"
          label="新卡号"
          placeholder="请输入新卡号"
					:disabled="form.newOperators === '4'"
          v-if="isReceive"
        />
        <van-field
          v-model="form.mobile"
          input-align="left"
          label="手机号码"
          type="number"
          placeholder="请输入手机号码"
          :rules="[{ validator:patternMobile, message: '请输入正确的手机号' }]"
          :disabled="form.newOperators === '4' || ![1,5].includes(changeCardData.status)"
        />
        <van-field
          v-model="form.recipients"
					name="validator"
          input-align="left"
          label="收件人"
          maxlength="10"
          placeholder="请输入收件人"
				 	:rules="[{ validator, message: '只能输入中文' }]"
          :disabled="form.newOperators === '4' || ![1,5].includes(changeCardData.status)"
        />
				<van-field
          v-model="form.addr"
          input-align="left"
          label="地区"
          maxlength="20"
          placeholder="请选择地区"
					:rules="[{ validator:validator3, message: '地区不能为空' }]"
					@click="popupShowFn"
					readonly
					:disabled="form.newOperators === '4' || ![1,5].includes(changeCardData.status)"
        />
        <van-field
          v-model="form.detailAddr"
          maxlength="30"
					rows="2"
          autosize
          type="textarea"
          input-align="left"
          label="详细地址"
          placeholder="请输入详细地址"
					show-word-limit
          :rules="[{ validator:validator4, message: '详细地址不能为空' }]"
          :disabled="form.newOperators === '4' || ![1,5].includes(changeCardData.status)"
        />
				<van-field
          v-model="form.freight"
          input-align="left"
          label="邮费"
					disabled
					v-if="isShowGetCardAd"
        />
        <van-cell :title="isShowGetCardAd ? '领取卡片' : '期望运营商' " value-class="operatorSelect">
          <van-radio-group v-model="form.newOperators" direction="horizontal" @change="changeHandle">
						<van-radio
							v-if="!isShowGetCardAd"
              name="4"
							:disabled="
                changeCardData.status != 1 && changeCardData.status != 5
              "
              :checked-color="tool.getThemeList(theme)"
              >移动WiFi</van-radio
            >
            <van-radio
              name="2"
              :disabled="
                changeCardData.status != 1 && changeCardData.status != 5
              "
              :checked-color="isShowGetCardAd ? '#FFA613' : tool.getThemeList(theme)"
              v-if="diagnosisData.operatorsOption?.includes('2')"
              >移动</van-radio
            >
            <van-radio
              name="3"
              :disabled="
                changeCardData.status != 1 && changeCardData.status != 5
              "
              :checked-color="isShowGetCardAd ? '#FFA613' : tool.getThemeList(theme)"
							v-if="!isShowGetCardAd || diagnosisData.operatorsOption?.includes('3')"
              >联通</van-radio
            >
            <van-radio
              name="1"
              :disabled="
                changeCardData.status != 1 && changeCardData.status != 5
              "
              :checked-color="isShowGetCardAd ? '#FFA613' : tool.getThemeList(theme)"
              v-if="diagnosisData.operatorsOption?.includes('1') && !operatorDefault"
              >电信</van-radio
            >
            <van-radio
              name="1"
              :disabled="
                changeCardData.status != 1 && changeCardData.status != 5
              "
              :checked-color="isShowGetCardAd ? '#FFA613' : tool.getThemeList(theme)"
              v-if="!diagnosisData.operatorsOption?.includes('1') && !diagnosisData.operatorsOption?.includes('2') && !diagnosisData.operatorsOption?.includes('3')"
              >电信</van-radio
            >
          </van-radio-group>
        </van-cell>
        <div class="copy_box" v-if="changeCardData.status == 3">
          <div class="logistics">物流信息:</div>
          <div class="copy_text">
            {{ changeCardData.expressCompany }}:{{ changeCardData.expressNo }}
          </div>
					<div class="copy_icon" :data-clipboard-text="changeCardData.expressNo"><img src="../../assets/image/personal/icon_copy.png" alt=""></div>
        </div>
				<!-- 按钮提交 -->
        <van-button
          block
          type="primary"
          native-type="submit"
          class="submit"
					:class="{isShowGetCardSubmit : isShowGetCardAd}"
          :disabled="flag"
          :style="`background:${theme == 'TianPeng'?'linear-gradient(263.2deg, #fd410dff 0%, #ff7a00ff 100%)':theme == 'XiaoMing'?'linear-gradient(263.2deg, #fd410dff 0%, #ff7a00ff 100%)':''};`" 
        >
          {{ buttonText }}{{ form.newOperators === '1' ? '(' + form.freight + '元' + ')' : ''}}
        </van-button>
				<div class="textTips" v-if="isShowGetCardAd" :style="`color: ${theme == 'XiaoMing' || theme == 'TianPeng'?'#000000':''};`">
					请务必填写正确的地址信息和手机号码，因地址错误和手机号码错误无法收到货物，本公司概不负责呢，提交申请表示您已知悉
				</div>
      </van-form>
    </div>
		<!-- 地址选择 -->
    <van-popup
      v-model:show="popupShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-area
        title="请选择地址"
        :area-list="areaList"
        @confirm="confirmArea"
        @cancel="canCelArea"
      />
    </van-popup>
		<!-- 换卡 使用方式 -->
		<van-action-sheet
      v-model:show="isCardUseShow"
      :actions="useCardList"
      title="使用方式"
      @select="selectuseCard"
    />
		<!-- 跳转租赁平台 提示弹窗 -->
		<ad-lease-dialog
			ref="adLeaseJumpDialog"
			v-if="!isShowGetCardAd && isLeaseJumpPopup"
		></ad-lease-dialog>
  </div>
</template>

<script>
import { getStore, removeStore , setStore } from "@/utils/store";
import { toRefs, reactive, onMounted, getCurrentInstance, computed } from "vue";
import { ExhchangeCard, fetchWxSchema , diagnosis} from "_API_/api.services";
import areaList from "@/utils/area";
import { debounce } from "lodash";
import ClipboardJS from "clipboard";
import { emptyObject } from "@/utils/utils";
import { useRoute } from "vue-router";
import { isDevice } from "@/utils/utils";
export default {
  setup() {
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;
    //获取sessionStorage的changeCardData
    let changeCardData = getStore({ name: "changeCardData" });
		//获取卡片是否为提示换卡
		let isShowGetCardAd = getStore({ name: "isShowGetCardAd"});
    //获取用户信息
    let info = store.state.user.userInfo;
     //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const state = reactive({
      theme: "", //主题名称
      platformCode: '', //平台code码
      changeCardData, //卡信息
      newCard: "", //新卡
      form: {
        card: info.virtualId || changeCardData.iccid,
        mobile: changeCardData.mobile || "",
        recipients: changeCardData.recipients || "",
        newOperators: changeCardData.newOperators
          ? String(changeCardData.newOperators)
          : "4",
        addr:
          changeCardData.address != null
            ? changeCardData.address.split(";")[0]
            : "",
        detailAddr:
          changeCardData.address != null
            ? changeCardData.address.split(";")[1]
            : "",
				freight: changeCardData.freight || 0 //邮费
      },
			typeVal:'4', //选项类型
      pattern: /^1\d{10}$/,//手机号码正则匹配
      popupShow: false, //area列表展示
      flag: false, //提交标志
			isCardUseShow:false, //是否展示卡使用方式
			isShowGetCardAd: isShowGetCardAd || false, //是否为提示换卡
			userOpenId: {}, //用户的OpenId
			payInfo:{},//支付所需的信息
			isPay:true,//是否支付
			isReceive:false,//是否收货
			isLeaseJumpPopup:false, //租赁平台弹窗
			useCardList:[{ name: '置换旧卡',exchangeCardType: 1 }, { name: '作为新卡使用',exchangeCardType: 2  }],
      diagnosisData:{},//配置信息
      operatorDefault:false,//是否显示默认电信运营商？
    });
		// 移动WiFi 弹窗
		// if(state.form.newOperators === '4'){
		// 	state.isLeaseJumpPopup = true
		// 	ctx.$nextTick(()=>{
		// 		ctx.$refs['adLeaseJumpDialog'].init()
		// 	})
		// }
    const methods = {
      async diagnosis(){
        await diagnosis(state.form.card,isDevice()).then(async ({ data: res }) => {
          if(res.data?.operatorsOption){
            res.data.operatorsOption = res.data.operatorsOption.split(',')
          }else{
            state.operatorDefault = true
          }
          if(res.data?.newOperators){
            state.form.newOperators = res.data.newOperators
          }
          state.diagnosisData = res.data
        });
      },
      /**
       * payParams: 支付参数 obj
       */
      fetchWxSchemaURL (payParams) {
        // 获取微信schema地址 （支付参数）
        fetchWxSchema(payParams).then(({data}) => {
          if(data.code == 0){
            return location.href = data.msg
          }
          console.log(data.msg);
        }).catch(err => {
          console.log(err)
        })
      },
			//类型选项
			changeHandle(val){
				state.typeVal = val
				if(Number(val) == 4){
					ctx.$refs['formData'].resetValidation()
					emptyObject(state.form,
					(obj, key)=>{
							if(key != 'card' && key != "freight" && key != 'newOperators'){ 
								obj[key] = ''
							}
						}
					)
				}
			},
			// 选择卡使用方式
			selectuseCard({exchangeCardType}){
				// 申请参数
        let conf = {
          oldCard: state.form.card,
          newCard: state.newCard,
					userWay: exchangeCardType
        };
				let msg = exchangeCardType == 1 ? '旧卡注销并转移信息到新卡，后续使用新卡，请确认' : '保持旧卡使用，新卡充值以后即可使用，请确认'
				tool.confirm(
        	{
          	msg,
          	title:`${exchangeCardType == 1 ? '置换旧卡' : '作为新卡使用'}`,
            confirmButtonText: "确定",
						cancelButtonText:"取消",
						confirmButtonColor:'#FF7301'
          },
          () => {
						state.isCardUseShow = false //关闭卡使用方式
            ctx.changeCard2(conf,exchangeCardType)
          }
        );
			},
			/**
			 * @handle patternMobile validator validator3 validator4
			 * @description 校验函数返回 true 表示校验通过，false 表示不通过
			 */
			patternMobile(val){
				if(state.form.newOperators != '4'){
					return state.pattern.test(val); //手机号码验证
				}
			},
			validator(val){
				if(state.form.newOperators != '4'){
					val = val.replace(/(^\s*)|(\s*$)/g, "") //消除前后空格
					return /^[\u4e00-\u9fa5]+$/.test(val); //只能输入中文
				}
			},
			validator3(val){
				if(state.form.newOperators != '4'){
					if(!Boolean(val)){
						return false
					}
					return true
				}
			},
      validator4(val){
        if(!Boolean(val)){
          return false
        }
        return true
			},
      //area列表展示控制
      popupShowFn() {
        if (
					state.form.newOperators === '4' ||
          (state.changeCardData.status != 1 &&
          state.changeCardData.status != 5)
        ) {
          state.popupShow = false;
          return;
        }
        state.popupShow = true;
      },
      //确定area列表
      confirmArea(arr) {
        state.popupShow = false;
        state.form.addr = arr.map(item => item.name).join(" ");
      },
      //取消area列表
      canCelArea() {
        state.popupShow = false;
      },
      //获取卡信息
      setCardData() {
        if (!changeCardData) {
          tool.toast(
            {
              msg: "当前卡片不允许换卡",
              duration: 1500
            },
            () => {}
          );
          return router.go(-1);
        }
      },
      // 旧的换卡方式
      changeCard(conf) {
				ExhchangeCard(conf, state.changeCardData.status).then(({ data }) => {
					if (data.code == 0) {
						// 若是正常换卡 且 为电信 且 (邮费大于 0 需支付邮费) 且 不是 已发货 且 不是驳回状态 则 发起微信支付
						if(!state.isShowGetCardAd && (state.typeVal === '1' || state.form.newOperators === '1') && Number(state.form.freight) > 0 && ![3,5].includes(state.changeCardData.status)){
							return ctx.wxFreight()
						}
						// 为提示换卡 且 邮费大于0  且 为待申请 则 发起微信支付
						if(state.isShowGetCardAd && Number(state.form.freight) > 0 && state.changeCardData.status == 1){
							return ctx.wxFreight()
						}
						state.flag = true;
						switch (state.changeCardData.status) {
							case 3:
								//从sessionStorage删除changeCardData与loginAccessToken标识
								removeStore({ name: "changeCardData", type: "session" });
								removeStore({ name: "loginAccessToken", type: "session" });
								tool.alert(
									{
										title: "提示",
										msg: "提交置换成功"
									},
									() => {
										router.push({
											name: "card-query"
										});
									}
								);
								return
							default:
								tool.alert(
									{
										title: "提示",
										msg: `提交${state.isShowGetCardAd ? '领取' : '置换'}申请成功`
									},
									() => {
										router.go(-1);
									}
								);
								return
						}
					}
          tool.toast(
            {
              msg: data.msg
            },
            () => {}
         	);
        });
      },
			// 新的换卡方式
			changeCard2(conf,exchangeCardType){
				ExhchangeCard(conf,state.changeCardData.status).then(({ data }) => {
          if (data.code == 0) {
            state.flag = true;
						// 移除changeCardData
						removeStore({ name: "changeCardData", type: "session" })
						// 移除套餐中心的换卡广告 
						removeStore({ name: `isChangeCardInPackage`,type: "session" });
						// 退出登录
						store.dispatch("UserLogout");
            switch (exchangeCardType) {
              case 1:
                tool.alert(
                  {
                    title: "提示",
                    msg: "提交置换成功"
                  },
                  () => {
										router.push({
                      name: "card-query",
                    })
                  }
                );
                return
							case 2:
								// 提示换卡成功 且 作为新卡使用时 保存标识
								setStore({
									name: "changeCard2",
									content: true,
									type: "session"
								});
								tool.toast(
									{
										msg: "提交信息成功"
									},
									() => {
										router.push({
                      name: "card-query",
											query:{
												iccid: conf.newCard
											}
                    })
									},
								);
                return
              default:
                tool.alert(
                  {
                    title: "提示",
                    msg: "提交申请成功"
                  },
                  () => {
                    router.go(-1);
                  }
                );
                return
            }
          }
          tool.toast(
            {
              msg: data.msg
            },
            () => {}
          );
        });
			},
			//提示换卡 支付邮费
			wxFreight(){
        /**
         * 参数说明
         * @param {*} paymentWxType   
         * 0-微信 1-通联 2-首赋 3-杉德支付
        */
        let paymentWxType = store.state.user.userInfo.paymentWxType;
        console.log('paymentWxType:',paymentWxType);
        /**
         * 参数说明
         * @param {*} config   接口所需的参数
         * @param {*} payType 1: 公众号支付  2: 小程序支付
         */
        let config = {
          payType: 2,
          platformCode: state.platformCode,
          trxamt: String(state.form.freight), // 邮费
          body: "微信支付邮费",
          openid: state.userOpenId ? state.userOpenId.openId : null,
          exchangeCardId:changeCardData.id,
          frontUrl: paymentWxType == 1 ? window.location.origin + `/pay-result` : window.location.origin
        };

				switch (info.device) {
          case "wx":
            config.tradeType = "JSAPI";
            break;
          case "other":
            config.tradeType = "MWEB";
            break;
          default:
            break;
        }
        if(info.device === 'other' && paymentWxType == 1){ // 走通联,小程序调起支付
          return methods.fetchWxSchemaURL(config)
        }
        if(info.device === 'wx' && paymentWxType == 0){ // 走原生微信jsapi调起支付
          let tongLianInfo = {
            isTongLian : false,
          }
          store.commit("Set_TongLian_Info", tongLianInfo);
        }
        if(info.device === 'wx' && paymentWxType == 1){ // 走通联,微信jsapi调起支付
          let tongLianInfo = {
            isTongLian : true,
            card: info.virtualId || info.iccid,
          }
          store.commit("Set_TongLian_Info", tongLianInfo);
        }
        // if(paymentWxType == 2){ // 走首赋 
        //   return 
        // }
				http.post(`/pay/wxFreight`,config).then(({data:res})=>{
					if (res.code == 333 || res.code == 444) {
						store.dispatch("SetUserInfo", {
							isConfigAppid: true,
							configAppid: res.data.appId,
						})
            removeStore({ name: "userOpenId", type: "session" });
						setStore({
							name: "router",
							content: "personal-changecard",
							type: "session"
						});
            location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appId}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`;
            return;
          }
          if (res.code == 0) {
            switch (info.device) {
              case "wx":
                Object.assign(state.payInfo, res.data);
                ctx.wxPay();
                break;
              case "other":
                // 存储isCharge到sessionStorage
                setStore({
                  name: "isCharge",
                  content: "1",
                  type: "session"
                });
                if(paymentWxType == 3) { // 走杉德
                  window.location.href = `${res.data.url}${tool.params(res.data)}`; // 杉德
                }else{
                  window.location.href = res.data.mweb_url; // 原有
                }
                break;
              default:
                break;
            	}
            return;
          } else {
            tool.alert(
          	  {
                title: "提示",
                msg: res.msg
              },
              state.isPay = false
            );
          }
          
				})
			},
			//JSAPI调起微信支付
      wxPay() {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              ctx.onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", ctx.onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", ctx.onBridgeReady);
          }
        } else {
          ctx.onBridgeReady();
        }
      },
      //JSAPI调起微信支付的事件函数
      onBridgeReady() {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: state.payInfo.appId,
            timeStamp: state.payInfo.timeStamp,
            nonceStr: state.payInfo.nonceStr,
            package: state.payInfo.package,
            signType: state.payInfo.signType,
            paySign: state.payInfo.paySign
          },
          function(res) {
            switch (res.err_msg) {
              case "get_brand_wcpay_request:ok":
                // 支付成功
								router.push({
									name: "personal"
								})
								break;
              case "get_brand_wcpay_request:cancel":
								// 取消支付
                tool.toast(
                  {
                    msg: "取消支付",
                    duration: 1500
                  },
                  state.isPay = false
                );
                break;
              case "get_brand_wcpay_request:fail":
								// 支付失败
                tool.toast(
                  {
                    msg: "支付失败",
                    duration: 1500
                  },
                  state.isPay = false
                );
                break;
              default:
                break;
            }
          }
        );
      },
			// 判断openId
			judgeOpenId() {
        if(getStore({ name: 'is_authorize' })){
          removeStore({ name: 'router', type: 'session' }); 
          return
        }
        if (
          (state.userOpenId === undefined || state.userOpenId.openId === "0" || !info.isPackage) &&
          info.device === "wx"
        ) {
          setStore({
            name: "router",
            content: "personal-changecard",
            type: "session"
          });
          setStore({
            name: "is_authorize",
            content: true,
            type: "session"
          });
          location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.configAppid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
          );
        }
      },
      //按钮提交
      onSubmit: debounce(
				function() {
      					// 状态列表
					let statusArray = [6,7,8]
					// 若是移动WiFi选项 则 跳转至租赁平台
					// if(state.form.newOperators === '4'){
					// 	return location.href = `http://web.zuji.xiaoyizuji.com/#/adv-dxhk/4?cardNo=${state.changeCardData.iccid}`
					// }
					//已申请 点击返回
          if(state.changeCardData.status == 2 && state.theme === 'YuYin'){
            router.push({
              name: "yy-platfrom"
            });
            return
          }    
					if(state.changeCardData.status == 2){
						window.history.back();
						return
					}
					// 换卡方式 为 正常换卡&提示换卡
          if (state.changeCardData.status != 3) {
            // 申请表单参数
            let conf = {
              ...state.form,
              address: state.form.addr + ";" + state.form.detailAddr
            };
						// 若为电信 且 未支付成功  或 为电信 且 待支付 
						if((state.typeVal === '1' && !state.isPay) || ((state.typeVal === '1' || state.form.newOperators === '1') && statusArray.includes(state.changeCardData.status))){
							return ctx.wxFreight()
						}
						// 浏览器环境 且 为电信 且 未支付成功 
						if(info.device == 'other' && (state.typeVal === '1' || state.form.newOperators === '1') && !state.isPay){
							return ctx.wxFreight()
						}
            ctx.changeCard(conf);
          }
					// 已发货
          if (state.changeCardData.status == 3) {
						state.isReceive = true //展示新卡号输入框
						// 换卡方式为提示换卡 且已经输入新卡号
						if(state.isShowGetCardAd && state.newCard != ""){
							// state.isCardUseShow = true //展示卡使用方式
              methods.selectuseCard({exchangeCardType:1})
							return
						}
            // 申请参数
            let conf = {
              oldCard: state.form.card,
              newCard: state.newCard
            };
						// 换卡方式为正常换卡 且 新卡号不为空
            if (state.newCard != "") {
              ctx.changeCard(conf);
              return;
            }
            tool.toast(
              {
                msg: "请输入新的卡号",
                duration: 1500
              },
              () => {}
            );
          }
        },
        1000,
        { leading: true, trailing: false }
      )
		};
		const buttonText = computed(()=>{
			// status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败 
			switch (state.changeCardData.status) {
				case 1:
					return '提交申请'
				case 2:
					return '返回'
				case 3:
					return '确认收货'
				case 5:
					return '重新提交'
				case 6:
				case 8:
					return '重新支付'
				default:
					return '提交申请'
			}
		})

		var clipboard = new ClipboardJS(".copy_icon");
    clipboard.on("success", e => {
      tool.toast(
        {
          msg: "快递单号复制成功",
          duration: 1500
        },
      );
      e.clearSelection();
    });
    clipboard.on("error", e => {
      tool.alert(
        {
          title: "提示",
          msg: `请长按复制快递单号\n${changeCardData.expressNo}\n`,
          confirmButtonText: "已复制"
        },
      );
    });
		onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode;
      // 换卡数据
      methods.setCardData();
			// 若为提示换卡 或 邮费大于0 则 需要获取openId信息
			if(state.isShowGetCardAd || Number(state.form.freight) > 0){
				document.title = `流量管理平台-领取卡片`;
				state.userOpenId = getStore({ name: "userOpenId" });
				//判断是否有openId
				methods.judgeOpenId();
				//h5支付结束
				let isCharge =
					getStore({
						name: "isCharge"
					}) || "2";
				if (isCharge === "1") {
					tool.confirm(
						{
							title: "提示",
							msg: "充值结果系统处理中"
						},
						() => {
							window.history.back();
						}
					);
					removeStore({ name: "isCharge", type: "session" });
				}
			}
      methods.diagnosis()
    });
    return { ...toRefs(state), ...methods, areaList, tool,buttonText };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.changeSteps,.changeContainer {
	height: 100vh;
	.steps {
    width: 750px;
    height: 176px;
    @include background_color("background_color1");
    .steps_con {
      width: 650px;
      height: 176px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      .step_status {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .step_icons {
          width: 63px;
          height: 63px;
          background: rgba(241, 242, 243, 0.2);
          border-radius: 50%;
          position: relative;
          div {
            width: 26px;
            height: 26px;
            background: #f1f2f3;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            left: 20px;
            background: #fff;
          }
        }
        .step_text {
          font: {
            size: 26px;
            weight: 400;
          }
          color: #f1f2f3;
        }
      }
      .step_status_1 {
        .step_icons {
          div {
            background: #f3e321;
          }
        }
        .step_text {
          color: #f3e321;
        }
      }

      .step_status_2,
      .step_status_3 {
        .step_icons {
          div {
            background: #3df321;
          }
        }
        .step_text {
          color: #3df321;
        }
      }
      .step_underline {
        width: 172px;
        height: 1px;
        margin-top: 52px;
        background: #ffffff;
      }
    }
  }
  .tips,.isShowGetCardAdTips {
    width: 690px;
    // height: 175px;
    border-radius: 20px;
    margin: 20px auto 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .tips_text {
      width: 600px;
      font: {
        size: 26px;
        weight: 400;
      }
      color: #333333;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 3; //指定行数
      // -webkit-box-orient: vertical;
      padding: 30px 0;
      .tips_text_list{
        line-height: 40px;
      }
    }
		.tips_border{
			border-left-width: 6px;
      border-left-style: solid;
      @include border_color("border_color1");
			padding: 20px;
		}
  }
	.tips{
		background: rgba(243, 202, 33, 0.2);
	}
	.isShowGetCardAdTips{
		background: #fff;
	}
  .content {
    width: 690px;
    margin: 10px auto;
    padding: 20px 10px;
    border-radius: 60px;
    overflow: hidden;
    /deep/ .operatorSelect {
      display: contents;
    }
		.textTips{
			color: #fff;
			font-size: 26px;
			padding: 10px;
		}
    .copy_box {
      display: flex;
      justify-content: space-around;
      height: 85px;
      line-height: 85px;
      background: #ffffff;
      .logistics,
      .copy_text {
        font-size: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
			.copy_icon{
				width: 32px;
				img{
					width: 24px;
				}
			}
      .logistics {
        width: 150px;
        text-align: center;
      }
      .copy_text {
        width: 500px;
        color: #aaaaaa;
      }
    }
		.tipComponent{
			padding: 10px 0px;
			background: #ffffff;
		}
		.rectangle{
			color: #fff;
			font-size: 24px;
			width: 80vw;
			text-align: center;
    	margin: auto;
			border-radius: 25px;
			background: #D0D8DA;
		}
		.triangle{
			margin: 0px;
			padding: 0px; 
			width: 0px;
			height: 0px;
			left: 195px;
			position: relative;
			.triangle_inner{
				margin: 0px;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid #D0D8DA;
				padding: 0px; 
				width: 0px;
				height: 0px;
				left: 19.333vw;
				top:-5.2vw;
				position: absolute;
			}
		}
    .submit,.isShowGetCardSubmit {
      margin-top: 20px;
			border: none;
  		border-radius: 20px;
    }
		.submit{
			@include background_color("background_color1");
		}
		.isShowGetCardSubmit{
			background: linear-gradient(to right,#FF7301,#FD4C0A);
		}
  }
}
.changeContainer{
	overflow-y: auto;
	background-color: #FFA613;
	.img{
		width: 100%;
	}
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px !important;
}
.van-radio--horizontal{
	margin-right: 8px !important;
}
</style>
